.scroll-to-top {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 99;
    &:hover {
        i {
            opacity: 1;
        }
    }
    i {
        padding: 20px;
        font-size: 40px;
        background-color: transparent;
        color: var(--primary-background);
        opacity: 0.5;
    }
}
