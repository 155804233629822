.year-study-progress {
  margin-bottom: 20px;

  .year-data {
    border: 1px solid black;
    border-top: none;
    display: none;
    overflow: hidden;
    padding: 15px 15px;
    &.active {
      display: block;
    }
  }

  .year-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid black;
    padding: 2px 16px;
    background-color: rgb(232, 229, 229);

    .year {
      font-weight: 550;
    }
  }

  .no-progress {
    text-align: center;
  }
}