.topic-card {
    margin-bottom: 30px;
    color: black !important;
    border-radius: 0.5rem;
    overflow: hidden;
    a {
        text-decoration: none;
        color: inherit;
    }
    .topic-photo {
        width: 100%;
        height: 250px;
        overflow: hidden;
        img {
            transition: 0.3s;
            height: 100%;
            width: 100%;
        }
    }
    .topic-info {
        background-color: var(--topic-card-background);
        padding: 0.6rem 1.2rem 1.8rem 1.2rem;
        line-height: 1.5;
        .topic-title {
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            text-transform: uppercase;
            font-weight: 600;
            color: var(--topic-card-name);
        }
        .topic-details {
            font-style: italic;
            font-size: small;
        }
        .topic-bagde {
            margin-bottom: 0.6rem;
            .topic-bagde-expired {
                background-color: darkgrey!important;
            }
            .topic-bagde-registered {
                background-color: #599900!important;
            }
            .topic-bagde-to-registered {
                background-color: rgb(255,193,7)!important;
            }
        }
    }
    &:hover {
        transition: 0.3s;
        box-shadow: 1px 25px 54px rgba(0, 0, 0, 0.2);
        .topic-photo {
            img {
                transform: scale(1.05);
            }
        }
    }
}
