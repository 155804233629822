.wrapper {
    display: flex;
    justify-content: center;
    height: 100vh;
    .body-content {
        padding-top: 70px;
        width: 100%;
        background-color: white;
        position: relative;
    }
    .spinner-border {
        width: 1.2rem;
        height: 1.2rem;
    }
}

.page-grid-view {
    margin-top: 15px;
}

@media (max-width: 2400px) {
    .main-content {
        width: 100%;
    }
}


@media (min-width: 2401px) {
    .main-content {
        width: 70%;
    }
}

@media only screen and (max-width: 500px) {
    .animationBg {
        width: 100% !important;
        .loginForm {
            width: 100% !important;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .animationBg {
        width: 80% !important;
        .loginForm {
            width: 80% !important;
        }
    }
}

@media only screen and (max-width: 1299px) {
    .page-grid-view {
        width: 80%;
    }
}

@media only screen and (min-width: 1300px) {
    .animationBg {
        width: 40% !important;
        .loginForm {
            width: 60% !important;
        }
    }
    .page-grid-view {
        width: 70%;
    }
}

@media only screen and (min-width: 1600px) {
    .page-grid-view {
        width: 60%;
    }
}

@media only screen and (min-width: 1900px) {
    .page-grid-view {
        width: 50%;
    }
}