header {
    height: 70px;
    background-color: var(--header-background);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1rem;
    width: 100%;

    box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.2);
    .club-name {
        padding-top: 6px;
    }
    .row {
        height: 100%;
        .container-center {
            display: flex;
            justify-content: center;
            align-items: center;
            height: inherit;
        }
    }
}
