.progress-container {
    border: 1px solid black;
    &.active {
        background-color: var(--study-progress-container);
        transition: 0.5s;
    }
}
.progress-detail {
    // position: absolute;
    top: 100%;
    border: 1px solid black;
    width: 100%;
    .score-container {
        .title {
            border: 1px solid black;
            padding: 1.5rem 2rem;
        }
        .score {
            // border: 1px solid black;
            padding: 1rem;
            line-height: normal;
            left: 0;
            right: 0;
            margin: auto;
            width: fit-content;
        }
    }
    .feedback-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        .title {
            width: 6rem;
            border: 1px solid black;
            padding: 1rem 0.8rem;
        }
        .content {
            border: 1px solid black;
            padding: 1rem 1rem;
            text-align: justify;
            width: 100%;
        }
        .total-score {
            position: relative;
            .user-score {
                position: absolute;
                right: 0.8rem;
            }
            .max-score {
                position: absolute;
                bottom: 1.3rem;
                left: 1rem;
            }
            .splash {
                font-style: italic;
                font-size: x-large;
            }
        }
    }
    visibility: hidden;
    opacity: 0;
    height: 0;
    &.active {
        visibility: visible;
        opacity: 1;
        transition: 1.5s;
        height: max-content;
    }
}
