.slot-card {
    .slot-start-date {
        color: #d1410c !important;
        font-weight: 400;
        vertical-align: middle;
    }
    .slot-info {
        margin-top: 4px;
        color: #6f7287;
        font-size: 13px;
    }
    .slot-icon {
        height: 17px;
        width: 17px;
        margin-right: 6px;
        color: #6f7287;
    }
    .card {
        border-radius: 12px;
        margin: 6px auto;
        background-color: var(--topic-card-background);
        &:hover {
            box-shadow: 0px 2px 8px rgba(30, 10, 60, 0.06), 0px 4px 12px rgba(30, 10, 60, 0.08);
        }
    } 
    .bottom-btn-container {
        margin-top: 15px;
        display: flex; 
        justify-content: space-between;
        .slot-submit-btn {
            border: 1px solid rgba(0,0,0,0);
            border-radius: 1.0869565217rem;
            color: #fff;
            font-weight: 500;
            text-align: center; 
        }
        .slot-register-btn {
            background-color: #599900;
            &:hover {
                background-color: #1e1f1c;
            }
        }
        .slot-cancel-btn {
            background-color: orange;
            &:hover {
                background-color: #1e1f1c;
            }
        }
        .google-calendar-btn {
            width: 30px;
            height: 30px;
            &:hover {
                width: 36px;
                height: 36px;
                transition: 0.5s;
            }
        }
    }
}
