body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html { 
  /**
   * Common
   */ 
  --primary-background: #599900;
  --foreground: rgb(252, 235, 198);
  --primary-body-background: white;
  
  /**
   * Header
   */
  --header-background: white;
  --home-header: #599900;

  /**
   * Topic
   */ 
  --topic-card-name: #4B7B08;
  --topic-card-background: rgb(246,246,246);
  --home-header: #599900;

  /**
   * User profile
   */ 
  --study-progress-container: rgb(89, 153, 0, 0.4);

  /**
   * User profile
   */ 
  --report-dropdown-container: #eaf2df;



  


}
