
@media screen and (max-width: 800px) {
    .animationBg {
        height: 500px !important;
    }
}

.loginBackground {
    background-image: url("../../assets/images/background.png");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .row {
        height: 100%;
        .login-content {
            justify-content: center;
            align-items: center;
            &:hover {
                .animationBg {
                    box-shadow: 0px 0px 50px #333;
                }
            }
            .animationBg {
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 80%;
                transition: box-shadow 0.5s;
                border-radius: 20px;
                flex-direction: column;
                &:hover {
                    box-shadow: 0px 0px 70px #000;
                    border-radius: 20px;
                }
                .loginForm {
                    .loginTitle {
                        margin-bottom: 1.5em;
                        line-height: 0.7em;
                        font-weight: 300;
                        text-align: center;
                        font-size: large;
                        font-weight: bold;
                        color: #599900;
                    }
                    width: 70%;
                    color: black;

                    text-align: left;
                    input {
                        font-size: small;
                        padding: 10px 10px;
                        border-radius: 2px;
                        border: none;
                        background-color: rgb(232, 240, 254);
                        width: 100%;
                        margin-bottom: 40px;
                        &:focus {
                            outline: none;
                        }
                    }

                    .login-btn {
                        background: #599900;
                        border: 1px solid #599900;
                        color: white;
                        &:hover {
                            background-color: transparent;
                            color: #599900;
                        }
                    }
                
                }
            }
        }
    }
}
