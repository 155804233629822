.report {
    .dropdown {
        .report-toggle {
            background-color: var(--report-dropdown-container);
        }
        button {
            color: black;
            width: 100%;
            text-align: left;
            &:focus {
                box-shadow: none;
            }
        }
    }

    .user-study-statistic {
        margin-top: 20px;
    }
}
