.info-group {
    font-size: smaller;
    justify-content: space-between;
    align-items: center;
    a {
        color: black;
        i {
            font-size: 18px;
            padding: 10px;
            border-radius: 50%;
            &:hover {
                box-shadow: 10px 0px 40px -5px var(--primary-background), inset 0px 0px 40px 0px white;
            }
        }
        &.active {
            i {
                transition: 0.8s;
                box-shadow: 0 0 0px 2px var(--primary-background), inset 0px 0px 40px 0px white;
            }
        }
    }
    .user-icon {
        img {
            height: 40px;
            width: 40px;
            background-color: white;
            &:hover {
                transition: 0.8s;
                box-shadow: 10px 0px 40px -5px var(--primary-background), 0 0 0px 2px var(--primary-background);
            }
        }
        .dropdown-switcher {
            &:hover {
                .dropdown {
                    visibility: visible;
                    opacity: 1;
                }
            }
            .dropdown {
                min-width: 160px;
                position: absolute;
                right: 1px;
                border: 1px solid #adadad;
                background-color: white;
                z-index: 999;
                opacity: 0;
                visibility: hidden;
                display: flex;
                flex-direction: column;
                background-color: var(--topic-card-background);
                .user-info {
                    display: flex;
                    align-items: center;
                    line-height: 35px;
                    padding: 0px 12px 0px 12px;
                }
                .dropdown-option {
                    display: flex;
                    align-items: center;
                    padding: 0px 12px 0px 12px;
                    font-weight: normal;
                    line-height: 35px;
                    color: var(--nav-modal-font-color);
                    text-decoration: none;
                    cursor: pointer;
                    &:hover {
                        background: #232323;
                        color: #fff;
                        text-decoration: none;
                    }
                }
                .dropdown-icon {
                    margin-right: 12px;
                    height: 1.2rem;
                    width: 1.2rem;
                }
            }
        }
    }
}
