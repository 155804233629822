.page-breadcrumb {  
  a {
    text-decoration: none;
  }

  .breadcrumb-item {
    &:not(:first-child)::before {
      content: ">" !important;
    }
  }
  .home-item {
    display: flex;
    align-items: center;
    gap: 5px;
    .home-icon {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }
}