.topic-registraion {
    .topic-banner {
        width: 100%;
        margin-bottom: 6px;
        border-radius: 7px;
    }
    .topic-details {
        border-radius: 10px;
        margin: 6px 0px;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 20px;
        .details-info {
            color: #585163;
            font-size: .875rem;
            .material-link {
                margin: 0 5px 5px 0;
            }
        }
        p {
            line-height: 1.5;
        }
    }
}